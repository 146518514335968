import Layout from "../components/layout"
import "../styles/contact.css"
import emailjs from "emailjs-com"
import React, { useState } from "react"
import validator from "validator"

function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [msg, setMsg] = useState("")
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    msg: "",
  })
  const validateEmail = e => {
    if (validator.isEmail(email)) {
      setEmailError("")
    } else {
      setEmailError("Invalid email")
    }
  }

  const validate = () => {
    let newObj = { ...errors }
    if (name.length <= 0) {
      newObj.name = "Name field cannot be empty"
      setErrors(newObj)
      return false
    } else {
      newObj.name = ""
      setErrors(newObj)
    }
    if (email == "") {
      setEmailError("Enter an email")
      return false
    }
    if (msg.length <= 0) {
      newObj.msg = "Please type a message"
      return false
    } else {
      newObj.msg = ""
      setErrors({ ...errors, msg: "" })
    }
    return true
  }

  const handleSubmit = e => {
    if (validate() && email.length <= 0) {
      alert("Enter email")
    } else if (validate()) {
      e.preventDefault()

      var templateParams = {
        name: name,

        email: email,

        msg: msg,
      }
      emailjs
        .send(
          "service_2hs29p7",
          "template_bap0r4w",
          templateParams,
          "Ee53uXOqQdY0MCdQ_"
        )
        .then(
          result => {
            console.log(result.text)
          },
          error => {
            console.log(error.text)
          }
        )
      alert("Successfully sent!") // setShowModal(false)
    }
  }
  return (
    <Layout route="/contact">
      <div className="grid grid-cols-12 contactPage">
        <div className="hidden md:block col-start-2 col-span-5">
          <div className="getInTouch">
            Get
            <br /> in <br />
            touch!
          </div>
        </div>
        <div className="col-start-2 col-span-10 md:col-start-8 md:col-span-5 formDiv">
          <div id="contactForm">
            <h1 className="contactHead mb-5 block md:hidden">Get in touch!</h1>
            <form class="w-full max-w-lg">
              <div class="flex flex-wrap -mx-3 mb-7">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-semibold mb-2"
                    for="grid-first-name"
                  >
                    Name
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-50 text-gray-700 border border-red-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                    id="grid-first-name"
                    type="text"
                    onChange={e => {
                      setName(e.target.value)
                    }}
                  />
                  <p className="error">{errors.name}</p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    E-mail
                  </label>
                  <input
                    class="appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="email"
                    type="email"
                    onChange={e => {
                      setEmail(e.target.value)
                      validateEmail(e)
                    }}
                  />
                  <p className="error">{emailError}</p>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mb-6">
                <div class="w-full px-3">
                  <label
                    class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    for="grid-password"
                  >
                    Message
                  </label>
                  <textarea
                    class=" no-resize appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                    id="message"
                    minLength={5}
                    maxLength={300}
                    onChange={e => {
                      setMsg(e.target.value)
                    }}
                  ></textarea>
                  <p className="error">{errors.msg}</p>
                </div>
              </div>
              <div class="md:flex md:items-center">
                <div class="md:w-1/3">
                  <button
                    class="shadow bg-teal-400 hover:bg-teal-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={e => handleSubmit(e)}
                  >
                    Send
                  </button>
                </div>
                <div class="md:w-2/3"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
